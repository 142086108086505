<template>
    <section>
        <div class="row mx-0 p-2 py-3 px-3 border-bottom align-items-center text-general">
            <template>
                <p v-show="[4,32,33,34].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Entregado</p>
                <p v-show="[23,27].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Cancelado Por {{$config.cliente}}</p>
                <p v-show="pedido.estado === 25"><b>ID. {{ pedido.id }} </b>Pedido Rechazado</p>
                <p v-show="[21,22,24,26].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Cancelado</p>


                <div v-show="pedido.manual === 0" class="d-middle ml-auto">
                    <div v-show="[21,22,24,26].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-cancel-circled-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="[23,27].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-account-remove-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="pedido.estado === 25" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-playlist-remove" />
                        <p>Rechazado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                </div>
                <div v-show="pedido.manual === 1" class="d-middle ml-auto">
                    <div class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-account-reactivate" />
                        <p>Manual </p>
                        <p> ({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                </div>
            </template>
        </div>
        <div class="row mx-0 text-general">
            <div class="col-12 px-0 h-100 overflow-auto custom-scroll">
                <div class="border-bottom">
                    <div class="row mx-0 align-items-center my-2 px-2">
                        <img class="border rounded-circle bg-whitesmoke" :src="cliente.avatar" width="50" height="50" alt="" />
                        <p class="mx-2 f-600">{{ cliente.nombre }}</p>
                        <div class="br-20 px-2 mx-2 bg-light-f5 text-general2">
                            <i class="f-20 icon-receipt" />
                            <span class="mx-2">{{ cliente.cant_pedidos }}</span>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-location text-general f-20 mr-2" />
                        <p class="col">{{ pedido.direccion }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-phone text-general f-22 mr-2" />
                        <p class="col f-15">{{ cliente.telefono || 'No registra' }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-calendar-clock text-general f-22 mr-2" />
                        <p class="col f-15">{{ pedido.entrega_fecha | helper-fecha('ddd DD MMMM') }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-truck-fast-outline text-general f-22 mr-2" />
                        <p class="col f-15">{{ convertMoney(pedido.valor_envio, $tienda.idm_moneda) }}</p>
                    </div>
                    <div class="row ml-3 mr-0 d-middle">
                        <span class="text-gr-general mr-2">Creditos</span>
                        <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                            <i class="icon-clock text-gr-general f-18" />
                        </el-tooltip>
                        <span class="f-14 mr-2">{{ convertMoney(pedido.saldo_vigente,$tienda.idm_moneda) }}</span>

                        <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                            <i class="icon-attention-alt text-danger f-18" />
                        </el-tooltip>
                        <span class="f-14">{{ convertMoney(pedido.saldo_vencido,$tienda.idm_moneda) }}</span>
                    </div>
                </div>
                <!-- linea de tiempo -->
                <div class="border-bottom py-2">
                    <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                        <div class="col-auto">
                            <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:44px;height:44px;padding:1px">
                                <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                    <i :class="`f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                                </div>
                            </div>
                            <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-general3':'bg-gris'" style="width:2px;" />
                        </div>
                        <div class="col">
                            <div class="row mx-0">
                                <div class="col px-1">
                                    <p :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                    <p class="f-15">{{ act.hora | helper-fecha('hh:mm a') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-bottom py-2">
                    <div class="row mx-0 px-2 cr-pointer" @click="verInstrucciones">
                        <i class="icon-card-text-outline f-20 text-gr-general" />
                        <div class="col my-auto">
                            Instrucciones de entrega
                        </div>
                        <i class="icon-angle-right f-20 text-gr-red" />
                    </div>
                </div>
                <div class="p-2">
                    <div class="row mx-0 px-2 text-general f-600 f-20">
                        Resumen
                    </div>
                    <div class="row mx-0 px-2 justify-content-between text-general">
                        <p>Costo de productos ({{ resumenes.inicial.cantidad_productos }})</p>
                        <b>{{ separadorNumero(resumenes.inicial.val_productos) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between text-general">
                        <p>Costo de envío</p>
                        <b>{{ separadorNumero(resumenes.inicial.val_domicilios) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between text-general">
                        <p>Descuento</p>
                        <b class="text-general">
                            {{ separadorNumero(resumenes.inicial.val_descuentos) }}
                        </b>
                    </div>
                    <div class="row mx-0 px-2 mt-2 f-600 text-general justify-content-between">
                        <p>Total a cobrar</p>
                        <p>{{ separadorNumero(resumenes.inicial.val_total) }}</p>
                    </div>
                </div>
                <div v-show="[110,11,12,13].indexOf(pedido.estado) > -1" class="p-2">
                    <div class="row mx-0 px-2 text-general f-600 f-20">
                        Nuevo resumen
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Costo de productos ({{ resumenes.final.cantidad_productos }})</p>
                        <b>{{ separadorNumero(resumenes.final.val_productos) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Costo de envío</p>
                        <b>{{ separadorNumero(resumenes.final.val_domicilios) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Descuento</p>
                        <b class="text-general">
                            {{ separadorNumero(resumenes.final.val_descuentos) }}
                        </b>
                    </div>
                    <div class="row mx-0 px-2 mt-2 f-600 text-general justify-content-between">
                        <p>Total a cobrar</p>
                        <p>{{ separadorNumero(resumenes.final.val_total) }} </p>
                    </div>
                </div>
                <div v-if="pedido.pago_estado != 1" class="row mx-0 mt-3 mb-3 px-1">
                    <div class="col-12 px-0">
                        <div v-show="pedido.pago_estado === 2 || pedido.pago_estado === 3" class="bg-general3 py-2 br-t-10 text-white header-credito d-middle-center f-600 f-15">
                            Crédito
                        </div>
                        <div v-show="pedido.pago_estado === 4" class="bg-gris header-credito d-middle-center f-600 f-15">
                            Crédito Anulado
                            <el-tooltip placement="bottom" :content="pedido.pago_anulado" effect="light">
                                <i class="icon-attention-alt f-18 text-gr-general ml-5" />
                            </el-tooltip>
                        </div>
                        <div class="borders pt-3" style="min-height:150px;">
                            <p class="text-general mb-2 f-15 f-600 text-center">
                                Cuotas establecidas
                            </p>
                            <div v-for="(cuota, c) in cuotas" :key="c" class="row mx-3" :class="colorTextoCredito(cuota)">
                                <p class="tres-puntos border-bottom text-general border-top py-2 col">
                                    <i class="f-18" :class="iconCredito(cuota)" />
                                    <span class="f-15">
                                        {{ cuota.fecha | helper-fecha('DD/MMM/YYYY') }}
                                    </span>
                                </p>
                                <p class="tres-puntos border-bottom border-top py-2 col-auto f-600 text-general">
                                    {{ convertMoney(cuota.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                            <p class="text-general my-2 f-15 f-600 text-center">
                                Historial de Pagos
                            </p>
                            <div v-for="(p, i) in pagos" :key="`key2-${i}`" class="row mx-3 border-bottom mb-3">
                                <p class="col f-14 tres-puntos">
                                    {{ p.created_at | helper-fecha('DD MMMM YYYY') }}
                                </p>
                                <div class="col-auto">
                                    <el-tooltip placement="bottom" :content="p.comentario" effect="light">
                                        <i class="icon-attention-alt f-18 text-general" />
                                    </el-tooltip>
                                </div>
                                <p class="col-12 f-14">
                                    {{ convertMoney(p.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <modalVerInstrucciones ref="modalVerInstrucciones" />
            </div>
        </div>
        <modalVerInstrucciones ref="modalVerInstrucciones" />
        <modalInformacion ref="modalInformacion" />
        <modalReclamos ref="modalReclamos" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import TiendaPedidos from '~/services/pedidos/pedidos-tienda'
import Pedido from '~/services/pedidos/pedidos-tienda'
import moment from 'moment'

export default {
    components: {
        modalVerInstrucciones: () => import('../partials/modalVerInstrucciones'),
        modalInformacion: () => import('../partials/modalInformacion'),
        modalReclamos: () => import('../partials/modalReclamos')
    },
    data(){
        return {
            buscar: '',
            activo: -1,
            pagina_actual:1,
            pedidos: [],
            productos: [],
            pedido:{
                tipo: 1,
                estado: 2,
                cant: null,
                updated_at: null,
                cant_mensajes: 0


            },
            editar: false,
            verDetalle: 0,
            estadoPedido: { estado: 'Reportado', icono: 'icon-cancel-circled-outline' },
            loading:false,
            cliente:{
                nombre: null,
                direccion: null,
                distancia: null
            },
            historial:[],
            resumenes:{inicial:{},final:{}},
            permitido: false,
            cuotas:[],
            pagos:[]
        }
    },

    computed:{
        ...mapGetters({
            id_pedido: 'reclamos/reclamos/id_pedido',
            // historial: 'reclamos/reclamos/historial',
            // detalle: 'reclamos/reclamos/detalle',
            // resumen: 'reclamos/reclamos/resumen'
        }),

    },
    watch:{
        id_pedido(value){
            if(value){
                this.initDetalle()
            }
        },
        historial(){
            this.historial[0].content = `Un ${this.$config.cliente} ha solicitado un pedido`;
        }
    },
    mounted(){
        this.initDetalle()
    },
    methods: {
        async initDetalle(){
            this.get_datos()
            this.listar_pedidos()
            this.historial_estados()
            this.listar_credito()
            this.listar_resumenes()
        },
        verInstrucciones(){
            this.$refs.modalVerInstrucciones.toggle(this.pedido)
        },
        async get_datos(){
            try {
                const {data} = await Pedido.get_datos(this.id_pedido)
                this.cliente = data.cliente
                this.pedido = data.pedido
            } catch (e){
                this.error_catch(e)
            }

        },
        async listar_pedidos(){
            try {
                if(this.id_pedido){
                    var {data} = await Pedido.consultar_un_pedido(this.id_pedido)
                    this.permitido = data.permitido
                    this.pedidos.push(data.pedido)



                    // var {data} = await Pedido.pedidos_productos(this.id_pedido)
                    // this.productos = data.productos
                    // this.cliente = data.cliente
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async historial_estados(){
            try {
                const {data} = await TiendaPedidos.historial_estados(this.id_pedido)
                let payload = data
                let mensajes = [
                    'Un cliente ha solicitado un pedido',
                    'Pedido Confirmado',
                    'Pedido con cambios',
                    'Pedido En Alistamiento',
                    'Pedido En Empaque',
                    'Pedido Enviado',
                    'Pedido Entregado',
                    'Pedido Cancelado',
                    'Reclamo',
                ]

                let iconos = [
                    'icon-receipt',
                    'icon-ok-circled',
                    'icon-account-edit',
                    'icon-cart-arrow-down',
                    'icon-shopping',
                    'icon-truck-fast-outline',
                    'icon-shopping',
                    'icon-attention-outline',
                ]

                let history = []
                for (let idx in payload){
                    if(idx == 2 || idx == 7 || idx == 8){

                        if(payload[idx] !== null){
                            let estado = this.pedido.estado
                            // console.log(estado);
                            let icon = ''
                            switch (estado){
                            case 25:
                                icon = 'icon-playlist-remove'
                                break;
                            case 26:
                                icon = 'icon-cancel-circled-outline'
                                break;
                            case 27:
                                icon = 'icon-account-remove-outline'
                                break;
                            default:
                                icon ='icon-attention-outline'
                            }

                            history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:icon })
                        }
                    }else{
                        history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
                    }
                }
                this.historial = history
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_resumenes(){
            try {
                const {data} = await TiendaPedidos.resumenes(this.id_pedido)
                this.resumenes = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_credito(){
            try {
                const {data} = await  TiendaPedidos.get_credito(this.id_pedido)
                this.cuotas = data.cuotas
                this.pagos = data.pagos
            } catch (e){
                this.error_catch(e)
            }
        },
        icon_estado(estado){
            switch (estado){
            case 4:
                return 'icon-shopping text-gr-general'
            case 21:
            case 22:
            case 24:
            case 26:
                return 'icon-cancel-circled-outline text-danger'
            case 23:
            case 27:
                return 'icon-account-remove-outline text-danger'
            case 25:
                return 'icon-playlist-remove text-danger'
            case 32:
            case 33:
            case 34:
                return 'icon-shopping text-gr-general'
            default:
                return ''
            }
        },
        verChat(){
            let soloVisual = false
            this.$refs.modalChat.toggle(this.id_pedido, 5, soloVisual) // 5 = tipo pedido admin
        },
        estado_pedido(estado){
            switch (estado){
            case 32:
            case 33:
            case 34:
            case 4:
                return 'Entregado'
            case 21:
            case 22:
            case 24:
            case 26:
                return 'Cancelado'
            case 23:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            default:
                return ''
            }
        },
        colorTextoCredito(cuota){
            if(cuota.valor_pendiente == 0){
                return 'text-primary'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'text-red'
            }
            return ''
        },
        iconCredito(cuota){

            if(cuota.valor_pendiente == 0){
                return 'icon-ok-circled-outline text-green'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'icon-attention-alt text-red'
            }

            return ''
        }

    }
}
</script>

<style lang="css" scoped>
</style>
